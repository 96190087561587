import React, { useContext, useMemo, useState } from 'react';
import { CheckoutDataContext } from 'context/merchant-details.context';
import {
  Box,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { useStyles } from './CustomerInfo';

const InvoiceDescriptionQuickLink = ({
  value,
  orderAmount,
  formData,
  setFormData,
  errors,
  setErrors,
}: any) => {
  const classes = useStyles();
  const { checkoutData } = useContext(CheckoutDataContext);
  const [showMore, setShowMore] = useState(false);
  const currency = checkoutData?.merchant.currency || 'EUR';
  const currencyFormatter = useMemo(
    () => new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        maximumFractionDigits: 2,
      }),
    []
  );

  return (
    <section className="flex box-invoice flex-between mobile-flex m-desc mm-0">
      <div>
        <Box className="flex space-between mobile-flex" mt={2}>
          <FormControl style={{ width: '25ch' }} variant="outlined">
            <div style={{ marginBottom: '10px' }}>
              <h1 className="m-main-label-size">Order Amount</h1>
            </div>
            {value === 0 ? (
              <>
                <OutlinedInput
                  aria-describedby="outlined-order-amount-helper-text"
                  className="order-amount-textbox"
                  error={errors.orderAmount}
                  id="outlined-adornment-order-amount"
                  inputProps={[
                    {
                      'aria-label': 'Order Amount',
                    },
                  ]}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      orderAmount: e.target.value.includes('.')
                        ? e.target.value.slice(0, e.target.value.indexOf('.') + 3)
                        : e.target.value,
                    });
                    setErrors({ ...errors, orderAmount: false });
                  }}
                  startAdornment={
                    <InputAdornment position="start">{currency}</InputAdornment>
                  }
                  value={orderAmount}
                />
                <Typography color="error" variant="caption">
                  {errors.orderAmount
                    ? `Order Amount must be valid number and between min and max value i.e ${checkoutData?.merchant.minAmount} to ${checkoutData?.merchant.maxAmount}`
                    : null}
                </Typography>
              </>
            ) : (
              <span className={`${classes.blueSpan} font-large`}>
                {currencyFormatter.format(orderAmount as any)}
              </span>
            )}
          </FormControl>
        </Box>
      </div>

      <div className="w-half ml-20 mm-0 mw-full">
        <h4>Descprition</h4>
        <span className="invoice-description-span color-light">
          {checkoutData?.merchant.description
          && checkoutData?.merchant.description.length < 60 ? (
            checkoutData?.merchant?.description
          ) : (
            <>
              {showMore
                ? checkoutData?.merchant?.description
                : `${checkoutData?.merchant?.description.substring(0, 50)}`}
              <Typography
                color="primary"
                onClick={() => setShowMore(!showMore)}
                sx={{ cursor: 'pointer' }}
              >
                {showMore ? 'Show less' : 'Show more'}
              </Typography>
            </>
          )}
        </span>
      </div>
    </section>
  );
};

export default InvoiceDescriptionQuickLink;
