import React, { useContext, useEffect, useState } from 'react';
import './OpttyDirectHeader.css';
import { CheckoutDataContext } from 'context/merchant-details.context';
import phoneImg from '../../assets/images/phone.svg';
import shieldImg from '../../assets/images/shield.svg';

const OpttyDirectHeader = () => {
  const { checkoutData, merchantData } = useContext(CheckoutDataContext);
  const [show, setShow] = useState(false);

  const googleTranslateElementInit = () => {
    // setCookie('googtrans', '/en/hi', 1);
    // eslint-disable-next-line no-new
    if (!show) {
      // eslint-disable-next-line no-new
      new google.translate.TranslateElement(
        {
          pageLanguage: 'en',
          layout: google.translate.TranslateElement.InlineLayout.HORIZONTAL,
          autoDisplay: true,
        },
        'google_translate_element'
      ); // eslint-disable-line
      setShow(true);
    }
  };

  useEffect(() => {
    const addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <header
      className="header"
      style={{ backgroundColor: `${checkoutData?.merchant.primaryColor}` }}
    >
      {merchantData?.logoUrl ? (
        <img alt="merchant logo" src={merchantData.logoUrl} />
      ) : (
        <h1 className="m-head m-m0">
          {checkoutData ? checkoutData?.merchant.merchantName : ''}
        </h1>
      )}
      <div id="google_translate_element" />
      <div className="flex mobile-flex space-between w-32 m-w-60 m-ml">
        <div className="flex mobile-flex m-flex-row headerBannerGuarantee">
          <img alt="phone" className="icon phone-icon" src={phoneImg} />
          <div>
            <p className="m-main-head m-m0">{merchantData?.supportContactNumber || ''}</p>
          </div>
          <p className="m-main-head m-m0">For support, contact us daily until 5pm</p>
        </div>
        <div className="flex mobile-flex headerBannerDivider m-divider" />
        <div className="flex mobile-flex m-flex-row headerBannerGuarantee">
          <img alt="shield" className="icon" src={shieldImg} />
          <div>
            <p className="m-main-head m-m0">SAFE AND SECURE</p>
            <p className="m-main-head m-m0">CHECKOUT GUARANTEED</p>
          </div>
        </div>
      </div>
    </header>
  );
};

export default OpttyDirectHeader;
