import React, {
 useContext, useEffect, useMemo, useRef, useState
} from 'react';
import { Box } from '@mui/material';
import OrderService from 'services/order.service';
import qs from 'query-string';
import { useReactToPrint } from 'react-to-print';
// import ReactToPrint from 'react-to-print';
import { CheckoutDataContext } from 'context/merchant-details.context';
import MessageContainer from 'components/MessageContainer/MessageContainer';
import GetPaidStepper from './GetPaidStepper';
import { useStyles } from './CustomerInfo';
import OrderSummary from './OrderSummary';
import InvoiceDescriptionGetPaid from './InvoiceDescriptionGetPaid';
import InvoiceDescriptionQuickLink from './InvoiceDescriptionQuickLink';
import Loader from '../../assets/images/loader.svg';

const OrderDetails = ({ value, bnplProvider }: any) => {
  const componentRef = useRef(null);
  const classes = useStyles();
  const { checkoutData } = useContext(CheckoutDataContext);
  const [amount, setAmount] = useState();
  const [tipAmount, setTipAmount] = useState();
  const [providerLogo, setProviderLogo] = useState();
  const [transactionDate, setTransactionDate] = useState();
  const {
 isLoading, setIsLoading, setError, error
} = useContext(CheckoutDataContext);
  // const status = 'success';
  // Workaround for getting the currency, since it isnt in the token
  const queryStrings: any = useMemo(() => qs.parse(window.location.search) as any, []);
  const { providerReference, status, reference } = queryStrings;
  useEffect(() => {
    setIsLoading(true);
    OrderService.getOrderData(
      providerReference,
      status,
      bnplProvider,
      queryStrings.session_token,
      reference
    )
      .then(async (response) => {
        return response;
      })
      .then((response) => {
        setAmount(response.amount);
        setTransactionDate(response.createdAt);
        setProviderLogo(response.providerLogo);
        OrderService.updateOrderStatus(
          {
            orderReferenceId: reference,
            amount: response.amount,
            status,
            paymentDate: new Date().toLocaleDateString(),
            paymentType: queryStrings.type,
          },
          queryStrings.session_token
        )
          .then(async (response) => {
            setTipAmount(response.tipAmount);
            return response;
          })
          .catch((error: unknown) => {
            setIsLoading(false);
            setError(error);
            console.error(error);
          });
      })
      .catch((error: unknown) => {
        setError(error);
        setIsLoading(false);
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [queryStrings]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      {isLoading && !error && (
        <MessageContainer
          headerText="Loading..."
          icon={Loader}
          isLoader
          subText="Please do not refresh the page"
        />
      )}
      {checkoutData && !isLoading && !error && (
        <div className="flex flex-center mb-60">
          <div className="main-checkout mb-20">
            <GetPaidStepper value={value} />

            {/* Invoice and tip sectionn */}
            <div className="print" id="scrollableDiv" ref={componentRef}>
              {checkoutData?.merchant.paymentType === 'CHPP' ? (
                <InvoiceDescriptionGetPaid
                  handlePrint={handlePrint}
                  orderAmount={amount}
                  value={value}
                />
              ) : (
                <InvoiceDescriptionQuickLink orderAmount={amount} value={value} />
              )}
              <div
                className={`${
                  checkoutData?.merchant.paymentType === 'GHPP'
                  && 'justify-content-center'
                } mt-70 flex mobile-flex flex-reverse`}
              >
                {/* Name And Address Form */}
                {checkoutData?.merchant.paymentType === 'CHPP' && (
                  <div
                    className="box checkout-address-form mobile-w-full m-m-70"
                    id="print-order-details"
                  >
                    <div className="flex flex-col">
                      <span className={`${classes.title} m-tile m-font-weight`}>
                        Order Details
                      </span>
                      <span className="m-font">
                        Please review personal information and order details
                      </span>
                    </div>

                    <Box>
                      <Box className="flex m-fields">
                        <div className="flex flex-col mr-8em m-0">
                          <span className="soft-label">Email</span>
                          <span className="m-font">{checkoutData?.merchant.email}</span>
                          <span className="soft-label">Phone Number</span>
                          <span className="m-font">
                            {checkoutData?.merchant.phoneNumber}
                          </span>
                        </div>
                      </Box>
                    </Box>
                  </div>
                )}
                <OrderSummary
                  orderAmount={amount}
                  paymentStatus={status}
                  providerLogo={providerLogo}
                  providerReference={providerReference}
                  tipAmount={tipAmount}
                  transactionDate={transactionDate}
                  value={value}
                />
              </div>
              <Box>
                {value === 2 ? (
                  <>
                    <button
                      className="colored-btn pointer padding-large w-half mt-10 m-w-full big-screen-off small-screen-on m-center m-mb-30"
                      id="printBtn"
                      onClick={() => {
                        handlePrint();
                      }}
                      type="button"
                    >
                      Print Receipt
                    </button>
                  </>
                ) : (
                  ''
                )}
              </Box>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default OrderDetails;
